<template>
    <div style="width: 100%">
        <v-list dense style="width: 100%; min-width: 300px">
            <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" color="primary" small>insights</v-icon>
                Actions</v-subheader>
            <v-divider></v-divider>
            <v-list-item @click="addUserRole()" style="height: 30px">
                <v-list-item-action class="mx-0 px-0">
                    <v-icon small color="grey">add</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title> Create Role </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" color="primary" small>insights</v-icon>
                Manage Roles</v-subheader>
            <v-divider></v-divider>
            <v-list-item v-for="(role, index) in roles" :key="index" @click="editRole(role)">
                <v-icon small color="var(--v-edit-base)" style="padding-right: 4px">fiber_manual_record</v-icon>
                {{ role.name }}
            </v-list-item>
        </v-list>

        <!-- Add/Edit Role dialog -->
        <v-dialog v-model="roleDialog" width="2500px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        <span v-if="addUsers">Add Role</span>
                        <span v-else>Manage Role</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="secondary" @click="saveRole()" :loading="savingRole"
                        :disabled="!roleItem.name"><v-icon>save</v-icon></v-btn>
                    <v-btn v-if="roleItem.id" icon color="red" :loading="deletingRole"
                        @click="removeRole(roleItem)">
                        <v-icon>delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="closeDialog()">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" :sm="!addUsers ? 3 : 6">
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary">info</v-icon>
                                Details</v-subheader>
                            <v-text-field label="Name" outlined dense clearable v-model="roleItem.name"></v-text-field>
                            <v-textarea label="Description" rows="6" outlined dense clearable
                                v-model="roleItem.description"></v-textarea>
                        </v-col>
                        <v-col cols="12" :sm="!addUsers ? 3 : 6">
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2"
                                    color="secondary">admin_panel_settings</v-icon> Permissions</v-subheader>
                            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchPermissions"
                                outlined dense clearable> </v-text-field>
                            <v-divider></v-divider>
                            <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-progress-linear color="primary" v-if="loadingPermissions"
                                    indeterminate></v-progress-linear>
                                <div v-for="(permission, index) in filterPermissions" :key="index">
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-icon color="grey">{{ permission.icon }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ permission.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn-toggle id="perms-toggle" dense text rounded :key="roleKey"
                                                v-model="permission.access" v-if="!permission.items">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="permission.access = 'none', createRoleAccess(permission, 'none')"
                                                            value="none" small
                                                            :color="permission.access == 'none' ? 'red' : 'grey'">
                                                            <v-icon small
                                                                :color="permission.access == 'none' ? 'red' : 'grey'">block</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">No Access</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="permission.access = 'read', createRoleAccess(permission, 'read')"
                                                            value="read" small
                                                            :color="permission.access == 'read' ? 'blue' : 'grey'">
                                                            <v-icon small
                                                                :color="permission.access == 'read' ? 'blue' : 'grey'">verified</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Standard</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="permission.access = 'admin', createRoleAccess(permission, 'admin')"
                                                            value="admin" small
                                                            :color="permission.access == 'admin' ? 'green' : 'grey'">
                                                            <v-icon small
                                                                :color="permission.access == 'admin' ? 'green' : 'grey'">security</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Admin</span>
                                                </v-tooltip>
                                            </v-btn-toggle>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <!-- <v-subheader style="font-size: 16px" ><v-icon class="mr-2" color="grey">{{ permission.icon }}</v-icon> {{ permission.name }}</v-subheader> -->
                                    <v-divider></v-divider>
                                    <v-list-item v-for="subitem in permission.items" :key="subitem.id">
                                        <v-list-item-action>
                                            <v-icon color="greyBase" small>fiber_manual_record</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ subitem.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn-toggle id="perms-toggle" dense text rounded :key="roleKey"
                                                v-model="subitem.access">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="subitem.access = 'none', createRoleAccess(subitem, 'none')"
                                                            value="none" small
                                                            :color="subitem.access == 'none' ? 'red' : 'grey'">
                                                            <v-icon small
                                                                :color="subitem.access == 'none' ? 'red' : 'grey'">block</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">No Access</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="subitem.access = 'read', createRoleAccess(subitem, 'read')"
                                                            value="read" small
                                                            :color="subitem.access == 'read' ? 'blue' : 'grey'">
                                                            <v-icon small
                                                                :color="subitem.access == 'read' ? 'blue' : 'grey'">verified</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Standard</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="subitem.access = 'admin', createRoleAccess(subitem, 'admin')"
                                                            value="admin" small
                                                            :color="subitem.access == 'admin' ? 'green' : 'grey'">
                                                            <v-icon small
                                                                :color="subitem.access == 'admin' ? 'green' : 'grey'">security</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Admin</span>
                                                </v-tooltip>
                                            </v-btn-toggle>
                                        </v-list-item-action>
                                    </v-list-item>
                                </div>
                                <v-list-item v-if="!loadingPermissions && filterPermissions.length == 0">
                                    <v-list-item-content class="text-center">
                                        <span style="color: grey">No permissions found</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="3" v-if="!addUsers">
                            <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    groups
                                </v-icon>
                                Role Users
                                <v-spacer></v-spacer>
                            </v-subheader>
                            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchUsers"
                                outlined dense clearable> </v-text-field>
                            <v-divider></v-divider>
                            <v-list dense subheader style="max-height: 60vh; overflow-y: auto" v-if="!addUsers">
                                <v-list-item v-if="(roleItem.userRoles && roleItem.userRoles.length == 0)">
                                    <v-list-item-content>
                                        <v-list-item-title style="color: grey">
                                            No linked users
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(user, index) in filteredUsers" :key="index">
                                    <v-list-item-action>
                                        <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                                            <v-img v-if="user.user.avatar" referrerpolicy="no-referrer"
                                                :src="user.user.avatar" contain></v-img>
                                            <h2 v-else style="color: white; font-weight: normal">{{
                                                user.user.firstname.charAt(0) }}</h2>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.user.firstname }} {{ user.user.surname }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ user.user.emailAddress }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn color="red" icon
                                            @click="removeUser(user, index)"><v-icon>block</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="3" v-if="!addUsers">
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary">person</v-icon>
                                Remaining Users
                                <v-spacer></v-spacer>
                            </v-subheader>
                            <v-text-field placeholder="Search" prepend-inner-icon="search"
                                v-model="searchRemainingUsers" outlined dense clearable> </v-text-field>
                            <v-divider></v-divider>
                            <v-list dense subheader style="max-height: 60vh; overflow-y: auto" v-if="!addUsers">
                                <v-list-item v-if="remainingUsers.length === 0 && !searchRemainingUsers">
                                    <v-list-item-content>
                                        No Remaining Users
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="remainingUsers.length === 0 && searchRemainingUsers">
                                    <v-list-item-content>
                                        No Remaining Users Found
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-else v-for="(user, index) in remainingUsers" :key="index">
                                    <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                                        <v-img v-if="user.user.avatar" referrerpolicy="no-referrer"
                                            :src="user.user.avatar" contain></v-img>
                                        <h2 v-else style="color: white; font-weight: normal">{{
                                            user.user.firstname.charAt(0) }}</h2>
                                    </v-avatar>
                                    <v-list-item-content style="padding-left: 8px;">
                                        <v-list-item-title>
                                            {{ user.user.firstname }} {{ user.user.surname }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ user.user.emailAddress }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-icon color="blue" @click="addUser(user)">add</v-icon>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
    components: {
        VuePdfEmbed,
    },
    data: () => ({
        orgId: null,
        item: null,
        selectedRows: [],
        searchRemainingUsers: '',
        addUsers: false,
        deletingRole: false,
        
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center',
        },
        {
            text: 'Description',
            value: 'description',
            align: 'center',
        }
        ],
        loading: false,
        loadingPermissions: false,
        permissions: [],
        roles: [],
        roleDialog: false,
        roleItem: {},
        roleSearch: '',
        roleKey: 1000,
        savingRole: false,
        searchPermissions: null,
        searchUsers: null,
        users: [],
        userLoading: [],
        userKey: 4000,
        event: null,
    }),
    async mounted() {
        this.item = this.params;
        if (this.params.api) {
            this.params.api.addEventListener(
                "selectionChanged",
                this.rowSelectionChanged
            );
        }
    },
    async created() {
        await this.load()
    },
    watch: {
        searchRemainingUsers: {
            immediate: true,
            handler(val) {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout);
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = undefined;
                    // this.gridApi.setGridOption("quickFilterText", val);
                }, 500);
            },
        },
    },
    computed: {
        filterPermissions(){
            let result = this.permissions
            if(this.searchPermissions){
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.searchPermissions.toLowerCase()) ||
                    (x.items && x.items.some(y=>y.name.toLowerCase().includes(this.searchPermissions.toLowerCase())))
                )
            }
            return result
        },
        filterRoles() {
            let result = this.roles
            if (this.roleSearch) {
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.roleSearch.toLowerCase())
                )
            }
            return result
        },
        filteredUsers() {
            let result = this.roleItem.userRoles ?? []
            if (this.searchUsers) {
                result = result.filter(x =>
                    (x.user.firstname.toLowerCase()).includes(this.searchUsers.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.searchUsers.toLowerCase())
                )
            }
            return result
        },
        remainingUsers(){
            let result = []
            if(this.roleItem && this.roleItem.userRoles){
                result = [...new Set(this.roleItem.userRoles.map(x=>x.user.id))]
                result = this.users.filter(x=>!result.includes(x.userId))
            }  
            result.sort((a,b)=>a.user.firstname.localeCompare(b.user.firstname))
            if (this.searchRemainingUsers) {
                result = result.filter(x =>
                    (x.user.firstname.toLowerCase()).includes(this.searchRemainingUsers.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.searchRemainingUsers.toLowerCase())
                )
            }
            return result
        }
    },
    methods: {
        // GET
        async getRoles() {
            this.loading = true;
            this.roles = await this.$API.getOrganisationRoleAdmin(this.orgId);
            this.loading = false;
            return;
        },
        async getOrganisationUsers() {
            return this.users = await this.$API.getOrganisationUsersAdmin(this.orgId);
        },
        // Created
        async load() {
            this.orgId = this.params.orgId,
            this.getOrganisationUsers();
            this.getRoles();
            return;
        },
        closeDialog() {
            this.searchUser = null;
            this.searchRemainingUsers = null;
            this.roleDialog = false;
            this.permissions = [];
            this.params.updateTable();
            this.addUsers = false;
        },
        async addUser(user) {
            try {
                if (this.roleItem.id) {
                    let result = await this.$API.createUserRoleAdmin({
                        batch: [{ userId: user.userId, roleId: this.roleItem.id }]
                    })
                    if (result && result.length > 0) {
                        let data = result[0];
                        data.user = user.user;
                        this.roleItem.userRoles.push(data);
                        user.data = data;
                    }
                    this.params.updateTable();
                    return this.$message.success('Added User To Role');
                } else {
                    throw new Error('Error');
                }
            } catch (e) {
                return this.$message.error(e);
            }
        },
        addUserRole(){
            this.addUsers = true;
            this.getPermissions();
            this.users = this.users.map(x=>({...x, added: false}));
            this.roleItem = { modalType: 'Add', userRoles: [], organisationId: this.orgId };
            this.roleDialog = true;
        },
        async createRoleAccess(item, type) {
            if (this.roleItem.id) {
                await this.$API.createRoleAccessAdmin({
                    roleId: this.roleItem.id,
                    route: item.route,
                    routeId: item.id,
                    routeTabId: null,
                    access: type
                })
            }
            this.roleKey++;
        },
        editRole(role) {
            this.roleItem = role;
            this.roleItem.modalType = 'Edit';
            this.getRolePermissions(this.roleItem.id);
            this.roleDialog = true;
        },
        async getPermissions() {
            this.loadingPermissions = true;
            this.permissions = await this.$API.getOrganisationPermission();
            for (let i=0;i<this.permissions.length;i++) {
                this.permissions[i].access = 'read'
                if (this.permissions[i].items) {
                    for (let j=0; j<this.permissions[i].items.length; j++) {
                        this.permissions[i].items[j].access = 'read'
                    }
                    // this.permissions[i].items.sort((a,b)=>a.name.localeCompare(b.name))
                }
            };
            this.loadingPermissions = false;
        },
        async getRolePermissions(id) {
            this.loadingPermissions = true;
            this.permissions = await this.$API.getRolePermissions(id);
            this.loadingPermissions = false;
        },
        queryRoleSearch(queryString, cb) {
            var links = this.roles;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            // call callback function to return suggestions
            cb(results);
        },
        async removeRole(role) {
            this.$confirm('Are you sure you want to delete this role?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingRole = true
                await this.$API.updateRoleAdmin({ id: role.id, isActive: false, isDeleted: true })
                this.roles.splice(this.roles.indexOf(role), 1)
                this.roleDialog = false;
                this.deletingRole = false;
                this.params.updateTable();
                this.$message.success('Successfully deleted!');
            }).catch(() => {
                this.$message.error('Delete cancelled');
                this.deletingRole = false
            });
        },
        async saveRole() {
            try {
                this.savingRole = true;
                if (this.roleItem.id) {
                    await this.$API.updateRoleAdmin(this.roleItem);
                } else {
                    let role = await this.$API.createRoleAdmin(this.roleItem);
                    this.roleItem.id = role.id;
                    console.log(this.roleItem);
                    let array = [...this.permissions.filter(x => !x.items)];
                    let items = [...this.permissions.filter(x => x.items).map(x => x.items).flat()];
                    let roles = [...array, ...items].map(x => ({ ...x, routeId: x.id, roleId: role.id }));
                    console.log(role);
                    await this.$API.createBatchRoleAccessAdmin({ batch: roles });
                    // await this.$API.createUserRoleAdmin({
                    //     batch: this.roleItem.userRoles.map(x => ({
                    //         userId: x.userId,
                    //         roleId: role.id
                    //     }))
                    // });
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.getRoles();
                this.roleItem = {};
                this.permissions = [];
                this.params.updateTable();
                this.addUsers = false;
                this.savingRole = false;
                this.roleDialog = false;
            }
        },
        async removeUser(role, index) {
            // let role = user.user.userRoles.find(x => x.roleId == this.item.id)
            if(role.id){
                await this.$API.createUserRoleAdmin({ batch: [{ id: role.id, isActive: false, isDeleted: true }] })
                let index = this.roleItem.userRoles.findIndex(x => x.id == role.id)
                this.roleItem.userRoles.splice(index, 1)
                this.$message.success('Successfully removed ' + role.user.firstname + ' ' + role.user.surname + ' from role!')
            } else {
                this.roleItem.userRoles.splice(index, 1)
                this.$message.success('Successfully removed ' + role.user.firstname + ' ' + role.user.surname + ' from role!')
            }
        },
        // AG GRID
        refresh() {
            if (this.params.refreshPanel) { this.load(); }
        },
        resetTable() {
            this.params.resetTable();
        },
        rowSelectionChanged(event) {
            if (event.source != "gridInitializing") {
                let rows = this.params.api.getSelectedRows();
                this.selectedRows = rows;
            } else {
                this.params.api.deselectAll();
                this.selectedRows = [];
            }
        },
        // Other
    },
};
</script>
